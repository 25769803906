<template>
  <div 
    v-if="!info.editable"
    class="item-box" 
    :class="{select: info.selected}"
    @click="clickBox(info, index)"
  >
    <span>{{ info.value }}</span>
  </div>
  <template v-else>
    <div
      v-if="active"
      class="input-box"
    >
      <input 
        type="text" 
        placeholder="Please share" 
        v-model.lazy.trim="info.value"
        ref="inputRef"
        @blur="done(info, index)"
      />
      <span 
        @click.stop="done(info, index)"
      >
        Done
      </span>
    </div>
    <div
      class="item-box"
      :class="{select: info.selected}"
      @click="clickBox(info, index)"
      v-else
    >
      <span>{{ otherValue }}</span>
      <span 
        class="edit"
      >
        <bv-tip 
          content="Edit"
        >
          <svg-icon
            v-if="info.value !== ''"
            name="icon_edit"
            color="#6741FF"
            class="edit-visible"
            :size="18"
            @click.stop="activateInput"
          />  
        </bv-tip>
      </span> 
    </div>
  </template>
</template>

<script setup>
const props = defineProps({
  info: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: 0,
  },
});
const emits = defineEmits([
  "select",
]);

const active = ref(false);
const inputRef = ref(null);
const otherValue = computed(() => {
  if (props.info.value === "") {
    return "Other";
  }
  else {
    return props.info.value;
  }
});

const activateInput = () => {
  active.value = true;
    nextTick()
      .then(() => {
        inputRef.value.focus()
      });
};

const clickBox = (item, index) => {
  if (item.value === "") {
    activateInput();
    return;
  }
  emits("select", item, index);
};

const done = (item, index) => {
  active.value = false;
  if(item.value !== "") {
    emits("select", item, index, true);
  }
  else {
    item.selected = false;
  }
};

</script>

<style lang="scss" scoped>
.item-box {
  height: 56px;
  position: relative;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #060606;
  border: 1px solid #E5E7EB;
  background: #FFF;
  cursor: pointer;

  & span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    height: 22px;
  }

  transition: all 200ms;
  &:hover, &.select {
    color: #6741FF;
    border: 1px solid #875EFF;
    background: #F8F5FF;
  }
} 

.edit {
  position: absolute;
  right: 18px;
}

.edit-visible {
  display: none;
}

.item-box:hover .edit-visible{
  display: block;
}

.input-box {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #875EFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  & input {
    width: 85%;
    border: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    &::placeholder {
      color: #8F959E;
    }
  }

  & span {
    color: #6741FF;
    cursor: pointer;
  }
}
</style>