import { openPremiumInTab } from "@/utils/premium";
import { openVideoEditor } from "@/router/open";
import facebookIcon from "@/assets/icons/home/icon_facebook.svg";
import instagramIcon from "@/assets/icons/home/icon_instagram.svg";
import pinterestIcon from "@/assets/icons/home/icon_pinterest.svg";
import linkedinIcon from "@/assets/icons/home/icon_linkedin.svg";
import twitterIcon from "@/assets/icons/home/icon_twitter.svg";
import youtubeIcon from "@/assets/icons/home/icon_youtube.svg";
import mediumIcon from "@/assets/icons/home/icon_medium.svg";
import type { Router } from "vue-router";

export type MenuItem =
  | {
      name: string;
      path: string;
      link?: boolean;
      query?: {
        [key: string]: string;
      };
    }
  | {
      name: string;
      handler: (router: Router) => void;
    };

export interface Menu {
  name: string;
  items: MenuItem[];
}

export type GroupMenuItem = MenuItem & {group?: string};

export type HeaderMenu = {
  name: string;
  items: GroupMenuItem[];
} | MenuItem;

export const headerMenuList: HeaderMenu[] = [
  {
    name: "Features",
    items: [
      {
        name: "Product URL to video",
        path: "/feature/product-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Idea to video",
        path: "/feature/idea-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Script to video",
        path: "/feature/script-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Blog to video",
        path: "/feature/blog-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Visuals to video",
        path: "/feature/visuals-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Templates to video",
        path: "/feature/template-to-video",
        link: true,
        group: "Video tools",
      },
      {
        name: "Start from scratch",
        path: "/feature/web-editor",
        link: true,
        group: "Video tools",
      },
      {
        name: "Image Enhancer",
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: "Image Background Remover",
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: "Object Eraser",
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: "Video Background Remover",
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
      {
        name: "Video Enhancer",
        path: "/feature/ai-tools",
        link: true,
        group: "AI tools",
      },
    ],
  },
  {
    name: "Use Cases",
    items: [
      {
        name: "E-commerce Seller",
        link: true,
        path: "/use-case/ecommerce-seller",
      },
      {
        name: "Content Creator",
        link: true,
        path: "/use-case/content-creator",
      },
      {
        name: "Social Media Marketer",
        link: true,
        path: "/use-case/social-media-marketer",
      },
      {
        name: "Photographer",
        link: true,
        path: "/use-case/photographer",
      },
      {
        name: "Artist",
        link: true,
        path: "/use-case/artist",
      },
    ],
  },
  // {
  //   name: "Playground",
  //   path: "/play-ground",
  // },
  {
    name: "Resources",
    items: [
      {
        name: "Blog",
        path: "https://boolv.video/blog/",
      },
      {
        name: "Newsletter",
        path: "https://socialmediamaven.substack.com/",
      },
      {
        name: "Changelog",
        path: "https://boolv.notion.site/Changelog-62ad113ea2fc42718002ecf225f88d44?pvs=74",
      },
      {
        name: "Roadmap",
        path: "https://roadmap.boolv.video/roadmap",
      },
      {
        name: "Feature Request",
        path: "https://roadmap.boolv.video",
      },
      {
        name: "Join Discord community",
        path: "https://discord.com/invite/WSC2FyGYCn",
      },
      {
        name: 'Affiliate Partner Program',
        path: "/affiliate-program",
      }
    ],
  },
  {
    name: "Pricing",
    // handler: openPremiumInTab,
    link: true,
    path: "/premium",
  },
];

export const footerMenuList: Menu[] = [
  {
    name: "Features",
    items: [
      {
        name: "Product URL to video",
        path: "/product-to-video",
        link: true,
      },
      {
        name: "Idea to video",
        path: "/idea-to-video",
        link: true,
      },
      {
        name: "Script to video",
        path: "/script-to-video",
        link: true,
      },
      {
        name: "Blog to video",
        path: "/blog-to-video",
        link: true,
      },
      {
        name: "Visuals to video",
        path: "/visuals-to-video",
        link: true,
      },
      {
        name: "Templates to video",
        path: "/template-video-list",
        link: true,
      },
      {
        name: "Start from scratch",
        path: "/editor",
        link: true,
      },
      {
        name: "Image Enhancer",
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'imageEnhancer',
        }
      },
      {
        name: "Image Background Remover",
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'backgroundRemover',
        }
      },
      {
        name: "Object Eraser",
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'objectErase',
        }
      },
      {
        name: "Video Background Remover",
        path: "/aiTools/upload",
        link: true,
        query: {
          aiToolType: 'videoBackgroundRemover',
        }
      },
      {
        name: "Video Enhancer",
        path: "/aiTools",
        link: true,
      },
    ],
  },
  {
    name: 'Playground',
    items: [
      {
        name: 'Playground',
        path: '/play-ground',
      }
    ]
  },
  {
    name: "Resources",
    items: [
      {
        name: "Blog",
        path: "https://boolv.video/blog/",
      },
      {
        name: "Newsletter",
        path: "https://socialmediamaven.substack.com/",
      },
      {
        name: "Changelog",
        path: "https://boolv.notion.site/Changelog-62ad113ea2fc42718002ecf225f88d44?pvs=74",
      },
      {
        name: "Roadmap",
        path: "https://roadmap.boolv.video/roadmap",
      },
      {
        name: "Feature Request",
        path: "https://roadmap.boolv.video",
      },
      {
        name: "Join Discord community",
        path: "https://discord.com/invite/WSC2FyGYCn",
      },
      {
        name: 'Affiliate Partner Program',
        path: "/affiliate-program",
        link: true,
      },
      {
        name: "Templates",
        path: "/template-gallery",
        link: true,
      },
    ],
  },
  {
    name: "Company",
    items: [
      {
        name: "Contact",
        path: "mailto:support@boolvector.com",
      },
      {
        name: "About us",
        path: "/about",
        link: true,
      },
      {
        name: "Partner",
        path: "https://www.quickcep.com/",
      },
      {
        name: "Terms of Service",
        path: "/terms",
        link: true,
      },
      {
        name: "Privacy Policy",
        path: "/privacy-policy",
        link: true,
      },
      {
        name: "Cookies Policy",
        path: "/cookies-policy",
        link: true,
      },
    ],
  },
];

export const socialMediaList = [
  {
    name: "twitter",
    link: "https://twitter.com/boolvtech",
    icon: twitterIcon,
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCkzHe1HYa5Ijcbci_WeBjYA",
    icon: youtubeIcon,
  },
  {
    name: "pinterest",
    link: "https://www.pinterest.com/boolv_tech/",
    icon: pinterestIcon,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/stylemastersss/",
    icon: instagramIcon,
  },
  {
    name: "medium",
    link: "https://medium.com/@boolv",
    icon: mediumIcon,
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/boolv-tech",
    icon: linkedinIcon,
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/Boolv-Tech-106608845463051",
    icon: facebookIcon,
  },
] as {
  name: string;
  link: string;
  icon: string;
}[];
