export const quesitionList = [
  {
    name: "role",
    question: "What best describes your role?",
    values: [
      "Business Owner",
      "Content Creator",
      "Marketer - at a company",
      "Marketer - agency or freelance",
      "Sales",
      "Designer",
      "Developer",
      "Filmmaker, Video Editor",
      "Hobbyist, Personal User",
    ],
    multiple: false,
    editable: true,
  },
  {
    name: "industry",
    question: "Which industry best describes your company?",
    values: [
      "eCommerce",
      "Retail",
      "Advertising",
      "Marketing Agency",
      "News, Media, Press",
      "Medical, Health Care",
      "Entertainment, Games, Sports",
      "Software, Online Service",  
      "Training, Education",
    ],
    multiple: false,
    editable: true,
  },
  {
    name: "teamSize",
    question: "What is the size of your company?",
    values: [
      "Only me",
      "2 - 10",
      "11 - 50",
      "51 - 200",
      "201 - 1000",
      "1000+",
    ],
    multiple: false,
    editable: false,
  },
  {
    name: "intentions",
    question: "What kind of videos do you want to create?",
    values: [
      "Ads, Promotion",
      "Product / Service Explainer",
      "Social Media Content",
      "eLearning, Knowledge Sharing",
      "Internal Training",
      "Client Communication",
      "Entertainment",
    ],
    multiple: true,
    editable: true,
  },
  {
    name: "approach",
    question: "How did you hear about us?",
    values: [
      "Twitter",
      "Youtube",
      "Linkedln",
      "TikTok",
      "Facebook, Instagram",
      "Google",
      "Forums, Blogs, Articles",
      "Friend, Word of Mouth",
    ],
    multiple: false,
    editable: true,
  },
]