<template>
  <div class="switcher-wrapper">
    <div class="switcher-container">
      <div 
        v-for="tab in tabs"
        class="tab" 
        :class="{active: tab.id === value}"
        @click="handleClick(tab.id)"
      >
        <p 
          v-if="tab.id === 'month'" 
          class="month"
        >
          {{ tab.name }}
        </p>
        <p v-else class="year">
          <img :src="gift" alt="gift_icon"/> 
          {{ tab.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import gift from "@/assets/icons/common/icon_gift.webp"
const props = defineProps({
  value: {
    type: Number,
    default: "month",
  },
})
const emit = defineEmits('switch');

const tabs = [
  {
    id: "year",
    name: "Yearly -20% off"
  },
  { 
    id: "month",
    name: "Monthly" 
  },
];

const selectId = ref(0);

const handleClick = (id) => {
  if (props.value === id) return;
  emit('switch', id);
};

const setup = () => {
  selectId.value = props.value;
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.switcher-wrapper {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px 0;
}

.switcher-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(180px, 1fr));
  padding: 3px;
  border-radius: 32px;
  border: 2px solid #313131;
  background: #FFFFFF;
}

.tab {
  width: 100%;
  border-radius: 27px;
  background: #FFFFFF;
  box-shadow: none;
  text-align: center;
  color: #060606;
  font-size: 16px;
  font-weight: 500;
  line-height: 54px;
  transition: all 0.2s;
  cursor: pointer;

  &.active {
    background: #000000;
    color: #FFFFFF;
  }

  & p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.year {
  & > img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}
</style>